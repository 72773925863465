/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetUserDetails = /* GraphQL */ `
  query GetUserDetails($input: GetUserDetails) {
    GetUserDetails(input: $input)
  }
`;
export const GetAllUsers = /* GraphQL */ `
  query GetAllUsers {
    GetAllUsers
  }
`;
export const ListAllOrganisationByStatus = /* GraphQL */ `
  query ListAllOrganisationByStatus($input: ListAllOrganisationByStatus) {
    ListAllOrganisationByStatus(input: $input)
  }
`;
export const GetAllMembersByOrganisationId = /* GraphQL */ `
  query GetAllMembersByOrganisationId($input: GetAllMembersByOrganisationId) {
    GetAllMembersByOrganisationId(input: $input)
  }
`;
export const GetAllAdminsOfPortal = /* GraphQL */ `
  query GetAllAdminsOfPortal($input: GetAllAdminsOfPortal) {
    GetAllAdminsOfPortal(input: $input)
  }
`;
export const GetAllTransactionsDetails = /* GraphQL */ `
  query GetAllTransactionsDetails {
    GetAllTransactionsDetails
  }
`;
export const GetAllInAppTxnDetails = /* GraphQL */ `
  query GetAllInAppTxnDetails {
    GetAllInAppTxnDetails
  }
`;
export const GetAllLocations = /* GraphQL */ `
  query GetAllLocations($input: GetAllLocations) {
    GetAllLocations(input: $input)
  }
`;
export const getReferal = /* GraphQL */ `
  query GetReferal {
    getReferal
  }
`;
export const GetAllMembersByStatus = /* GraphQL */ `
  query GetAllMembersByStatus($input: GetAllMembersByStatus) {
    GetAllMembersByStatus(input: $input)
  }
`;
export const GetAllOrganisationSwipes = /* GraphQL */ `
  query GetAllOrganisationSwipes($input: GetAllOrganisationSwipes) {
    GetAllOrganisationSwipes(input: $input)
  }
`;
export const GetAllAuctionsOfOrganisations = /* GraphQL */ `
  query GetAllAuctionsOfOrganisations($input: GetAllAuctionsOfOrganisations) {
    GetAllAuctionsOfOrganisations(input: $input)
  }
`;
export const ListAllOrganisation = /* GraphQL */ `
  query ListAllOrganisation($input: ListAllOrganisation) {
    ListAllOrganisation(input: $input)
  }
`;
export const ListOrganisationCredits = /* GraphQL */ `
  query ListOrganisationCredits($input: ListOrganisationCredits) {
    ListOrganisationCredits(input: $input)
  }
`;
export const ListAllTimezones = /* GraphQL */ `
  query ListAllTimezones {
    ListAllTimezones
  }
`;
export const ValidateQr = /* GraphQL */ `
  query ValidateQr($input: ValidateQr) {
    ValidateQr(input: $input) {
      message
      qr_id
      __typename
    }
  }
`;
export const ListQrsDifferentStates = /* GraphQL */ `
  query ListQrsDifferentStates($input: ListQrsDifferentStates) {
    ListQrsDifferentStates(input: $input) {
      data {
        Items {
          prznce_qr_count
          qr_id
          qr_created_by
          qr_created_on
          qr_location_name
          organisation_name
          qr_claimed_on
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const PrznceBmsDashboard = /* GraphQL */ `
  query PrznceBmsDashboard($input: PrznceBmsDashboard) {
    PrznceBmsDashboard(input: $input)
  }
`;
export const ListEmailTriggers = /* GraphQL */ `
  query ListEmailTriggers {
    ListEmailTriggers
  }
`;
export const GetDailyScans = /* GraphQL */ `
  query GetDailyScans($input: GetDailyScans) {
    GetDailyScans(input: $input)
  }
`;
export const GtMonthlyScans = /* GraphQL */ `
  query GtMonthlyScans($input: GtMonthlyScans) {
    GtMonthlyScans(input: $input)
  }
`;
export const GlobalDailyScans = /* GraphQL */ `
  query GlobalDailyScans($input: GlobalDailyScans) {
    GlobalDailyScans(input: $input)
  }
`;
export const GetAllLocationsGlobaly = /* GraphQL */ `
  query GetAllLocationsGlobaly($input: GetAllLocationsGlobaly) {
    GetAllLocationsGlobaly(input: $input)
  }
`;
export const GetSubLocations = /* GraphQL */ `
  query GetSubLocations($input: GetSubLocations) {
    GetSubLocations(input: $input)
  }
`;
export const GetReferralPending = /* GraphQL */ `
  query GetReferralPending {
    GetReferralPending
  }
`;
export const GlobalyMonthlyScans = /* GraphQL */ `
  query GlobalyMonthlyScans {
    GlobalyMonthlyScans
  }
`;
export const DashboardDailyScans = /* GraphQL */ `
  query DashboardDailyScans($input: DashboardDailyScans) {
    DashboardDailyScans(input: $input)
  }
`;
export const ListSalesRep = /* GraphQL */ `
  query ListSalesRep($input: ListSalesRep) {
    ListSalesRep(input: $input)
  }
`;
export const GetTransactionOfOrganisation = /* GraphQL */ `
  query GetTransactionOfOrganisation($input: GetTransactionOfOrganisation) {
    GetTransactionOfOrganisation(input: $input)
  }
`;
export const CountOfOrganisation = /* GraphQL */ `
  query CountOfOrganisation {
    CountOfOrganisation
  }
`;
export const getSalesReferal = /* GraphQL */ `
  query GetSalesReferal {
    getSalesReferal
  }
`;
export const getSwipesOfOrganisations = /* GraphQL */ `
  query GetSwipesOfOrganisations($input: getSwipesOfOrganisations) {
    getSwipesOfOrganisations(input: $input)
  }
`;
export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserDetailsSales) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const GetAllOrganisationReferredBySales = /* GraphQL */ `
  query GetAllOrganisationReferredBySales(
    $input: GetAllOrganisationReferredBySales
  ) {
    GetAllOrganisationReferredBySales(input: $input)
  }
`;
export const SalesPrzncePortalDashboard = /* GraphQL */ `
  query SalesPrzncePortalDashboard($input: SalesPrzncePortalDashboard) {
    SalesPrzncePortalDashboard(input: $input)
  }
`;
export const DashboardDailyScansSales = /* GraphQL */ `
  query DashboardDailyScansSales($input: DashboardDailyScansSales) {
    DashboardDailyScansSales(input: $input)
  }
`;
export const GetAllMembersByOrganisationIdSales = /* GraphQL */ `
  query GetAllMembersByOrganisationIdSales(
    $input: GetAllMembersByOrganisationIdSales
  ) {
    GetAllMembersByOrganisationIdSales(input: $input)
  }
`;
export const GetAllAdminsOfPortalSAles = /* GraphQL */ `
  query GetAllAdminsOfPortalSAles($input: GetAllAdminsOfPortalSAles) {
    GetAllAdminsOfPortalSAles(input: $input)
  }
`;
export const GetAllLocationsSales = /* GraphQL */ `
  query GetAllLocationsSales($input: GetAllLocationsSales) {
    GetAllLocationsSales(input: $input)
  }
`;
export const CountOfOrganisationSales = /* GraphQL */ `
  query CountOfOrganisationSales($input: CountOfOrganisationSales) {
    CountOfOrganisationSales(input: $input)
  }
`;
export const TransactionOfOrgansiationSales = /* GraphQL */ `
  query TransactionOfOrgansiationSales($input: TransactionOfOrgansiationSales) {
    TransactionOfOrgansiationSales(input: $input)
  }
`;
export const GetDailyScansSales = /* GraphQL */ `
  query GetDailyScansSales($input: GetDailyScansSales) {
    GetDailyScansSales(input: $input)
  }
`;
export const GetAllLoctionOfOrganisationsSales = /* GraphQL */ `
  query GetAllLoctionOfOrganisationsSales(
    $input: GetAllLoctionOfOrganisationsSales
  ) {
    GetAllLoctionOfOrganisationsSales(input: $input)
  }
`;
export const GetSubLocationsSales = /* GraphQL */ `
  query GetSubLocationsSales($input: GetSubLocationsSales) {
    GetSubLocationsSales(input: $input)
  }
`;
export const GetSwipesOfOrganisationSales = /* GraphQL */ `
  query GetSwipesOfOrganisationSales($input: GetSwipesOfOrganisationSales) {
    GetSwipesOfOrganisationSales(input: $input)
  }
`;
export const GetOrganisationMonthlyScansSales = /* GraphQL */ `
  query GetOrganisationMonthlyScansSales(
    $input: GetOrganisationMonthlyScansSales
  ) {
    GetOrganisationMonthlyScansSales(input: $input)
  }
`;
export const GetDashboardMonthlyScansSales = /* GraphQL */ `
  query GetDashboardMonthlyScansSales($input: GetDashboardMonthlyScansSales) {
    GetDashboardMonthlyScansSales(input: $input)
  }
`;
export const getWorkShiftForOrganisation = /* GraphQL */ `
  query GetWorkShiftForOrganisation($input: GetWorkShiftForOrganisationInput) {
    getWorkShiftForOrganisation(input: $input)
  }
`;
export const getMyPrezenceLatestVersion = /* GraphQL */ `
  query GetMyPrezenceLatestVersion {
    getMyPrezenceLatestVersion
  }
`;
export const getAdminLatestVersion = /* GraphQL */ `
  query GetAdminLatestVersion {
    getAdminLatestVersion
  }
`;
export const country_credits_list_edit = /* GraphQL */ `
  query Country_credits_list_edit($input: CountryCreditsListEditInput) {
    country_credits_list_edit(input: $input)
  }
`;
export const list_forms = /* GraphQL */ `
  query List_forms($input: ListFormsInput) {
    list_forms(input: $input)
  }
`;
export const list_forms_library = /* GraphQL */ `
  query List_forms_library($input: ListFormsInput) {
    list_forms_library(input: $input)
  }
`;
export const list_transactions_reports = /* GraphQL */ `
  query List_transactions_reports($input: ListTransactionsReportsInput) {
    list_transactions_reports(input: $input)
  }
`;
export const list_banners = /* GraphQL */ `
  query List_banners($input: ListBannersInput) {
    list_banners(input: $input)
  }
`;
